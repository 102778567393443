<template>
	<div>
		<DeletarDialog
			:abrirDialog="excluir"
			@dialogClose="dialogCloseDeletar"
			@deletar="deletar"
			mensagem="Você realmente deseja excluir esse download?"
		/>
		<v-dialog v-model="downloadDialog" max-width="800" scrollable>
			<v-card>
				<v-card-title>
					<v-icon left>mdi-download</v-icon>Cadastro de Download
					<v-tooltip bottom v-if="download.id">
						<template v-slot:activator="{ on }">
							<v-icon right color="primary" v-on="on">mdi-identifier</v-icon>
						</template>
						<span>{{ download.id }}</span>
					</v-tooltip>
					<v-spacer></v-spacer>
					<v-btn icon @click="dialogClose">
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="10">
							<v-text-field
								label="Nome"
								v-model="download.titulo"
								:readonly="!manterDownloads"
								autocomplete="off"
							></v-text-field>
						</v-col>
						<v-col cols="2">
							<v-text-field
								label="versão"
								v-model="download.versao"
								:readonly="!manterDownloads"
							></v-text-field>
						</v-col>
					</v-row>

					<v-text-field label="Tags" v-model="download.tags" :readonly="!manterDownloads"></v-text-field>
					<v-text-field
						label="Link para download"
						v-model="download.linkdownload"
						:readonly="!manterDownloads"
					></v-text-field>
					<v-text-field
						label="Link para documentação"
						v-model="download.linkdocumentacao"
						:readonly="!manterDownloads"
					></v-text-field>

					<div>
						<v-row v-if="manterDownloads">
							<v-col>
								<v-btn class="mx-1" color="success" @click="salvar()">
									<v-icon left>mdi-content-save</v-icon>
									Salvar
								</v-btn>
								<v-btn v-if="id" class="mx-1" color="error" @click="abrirDialogDeletar">
									<v-icon left>mdi-delete</v-icon>
									deletar
								</v-btn>
							</v-col>
						</v-row>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DeletarDialog from '../shared/DeletarDialog.vue'

export default {
	props: {
		id: String,
		dialog: Boolean,
	},
	components: { DeletarDialog },
	data: function () {
		return {
			download: {},
			loading: false,
			tab: '',
			excluir: false,
			solicitacoes: [],
			tiposDePxSelector: ['PxGeral', 'PxLoja', 'PxUsers', 'PxRet', 'Outros'],
			dialogSolicitacoes: false,
		}
	},
	computed: {
		...mapGetters(['manterDownloads', 'userMaster']),
		...mapState(['user']),
		downloadDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		async salvar() {
			if (this.id) {
				this.editar()
			} else {
				this.criar()
			}
		},
		async criar() {
			try {
				this.loading = true
				const response = await this.axios.post('/downloads', {
					titulo: this.download.titulo,
					versao: this.download.versao,
					tags: this.download.tags,
					linkdownload: this.download.linkdownload,
					linkdocumentacao: this.download.linkdocumentacao,
				})

				if (response.status == 201) {
					this.$store.dispatch('showSuccess', 'Download cadastrado com sucesso!')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async editar() {
			try {
				this.loading = true
				const response = await this.axios.put(`/downloads/${this.id}`, {
					titulo: this.download.titulo,
					versao: this.download.versao,
					tags: this.download.tags,
					linkdownload: this.download.linkdownload,
					linkdocumentacao: this.download.linkdocumentacao,
				})

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Download alterado com sucesso!')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		dialogClose() {
			this.download = {}
			this.$emit('dialogClose')
		},
		dialogCloseDeletar() {
			this.excluir = false
		},
		abrirDialogDeletar() {
			this.excluir = true
		},
		async loadDownload() {
			if (this.id) {
				try {
					this.loading = true
					const response = await this.axios.get(`/download/${this.id}`)
					this.download = response.data.download
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loading = false
				}
			}
		},
		async deletar() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/downloads/${this.id}`)
				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Download foi excluído com sucesso!')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		dialog() {
			if(this.dialog) this.loadDownload()
		},
	},
}
</script>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
</style>
