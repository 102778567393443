<template>
	<v-main>
		<v-container :class="!user ? 'fill-height' : ''" fluid>
			<v-fade-transition mode="out-in">
				<router-view />
			</v-fade-transition>
		</v-container>
	</v-main>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	metaInfo() {
		return {
			title: 'SIC Help',
		}
	},
	computed: {
		...mapGetters({user: 'user'}),
	},
}
</script>
