<template>
	<v-navigation-drawer v-model="inputValue" :clipped="$vuetify.breakpoint.lgAndUp" app>
		<v-list dense>
			<template v-for="item in links">
				<v-list-item :key="item.text" link :to="item.to" v-if="checkVisibility(item)">
					<v-list-item-action>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
	</v-navigation-drawer>
</template>
<script>
export default {
	data: () => ({
		links: [
			{
				to: '/wiki',
				icon: 'mdi-book-open-blank-variant',
				text: 'Wiki',

				//admin: true,
			},
			{
				to: '/downloads',
				icon: 'mdi-download',
				text: 'Downloads',
				//admin: true,
			},
			/*{
				to: '/pxacerto',
				icon: 'mdi-tune',
				text: 'PxAcerto',
				admin: false,
			},
			{
				to: '/ajuda',
				icon: 'mdi-help-box',
				text: 'Ajuda',
				admin: false,
			},*/
			{
				to: '/usuarios',
				icon: 'lock',
				text: 'Usuários',
				admin: true,
			},
			{
				to: '/hierarquia',
				icon: 'mdi-account-group',
				text: 'Hierarquia',
				master: true,
			},
		],
	}),
	computed: {
		inputValue: {
			get() {
				return this.$store.state.drawer
			},
			set(val) {
				this.$store.commit('setDrawer', val)
			},
		},
	},
	methods: {
		checkVisibility(item) {
			if (item.admin || item.master) {
				if (item.admin && this.$store.getters.manterUsuarios) return true
				else if (item.master && this.$store.getters.userMaster) return true
				else return false
			}
			return true
		},
	},
}
</script>
