<template>
	<div>
		<DownloadsDialog :dialog="dialogDownloads" :id="id" @dialogClose="closeDialogDownloads()" />
		<v-card>
			<v-card-title> <v-icon left color="primary">mdi-download</v-icon>Downloads</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Pesquisar"
							single-line
							hide-details
						></v-text-field>
					</v-col>
				</v-row>
				<v-row v-if="manterDownloads">
					<v-col>
						<v-btn color="primary" @click="openDialogDownloads"
							><v-icon left>mdi-plus</v-icon>Novo download</v-btn
						>
					</v-col>
				</v-row>
				<v-data-table
					:headers="headers"
					:items="downloads"
					:options.sync="options"
					:server-items-length="total"
					class="elevation-1"
					:loading="loading"
					dense
				>
					<template v-slot:[`item.titulo`]="{ item }">
						<div @click.stop="rowSelected(item)">{{ item.titulo }}</div>
					</template>
					<template v-slot:[`item.versao`]="{ item }">
						<div @click.stop="rowSelected(item)">{{ item.versao }}</div>
					</template>
					<template v-slot:[`item.tamanho`]="{ item }">
						<div @click.stop="rowSelected(item)">{{ item.tamanho }}</div>
					</template>
					<template v-slot:[`item.tags`]="{ item }">
						<v-chip
							@click.stop="rowSelected(item)"
							class="ma-1 text-caption"
							v-for="tag in item.tags"
							:key="tag"
							color="primary"
						>
							{{ tag }}
						</v-chip>
					</template>
					<template v-slot:[`item.download`]="{ item }">
						<v-btn text :href="item.linkdownload" icon>
							<v-icon v-if="item">mdi-download</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import DownloadsDialog from '../components/downloads/DownloadsDialog.vue'

export default {
	components: { DownloadsDialog },
	data: function () {
		return {
			dialogDownloads: false,
			loading: false,
			id: '',
			options: {},
			search: '',
			total: 0,
			downloads: [],
			headers: [
				{ text: 'Título', value: 'titulo' },
				{ text: 'Versão', value: 'versao', align: 'end' },
				{ text: 'Tags', value: 'tags' },
				{ text: 'Tamanho', value: 'tamanho', align: 'end' },
				{ text: '', value: 'download' },
			],
		}
	},
	computed: {
		...mapGetters(['manterDownloads']),
	},
	methods: {
		async loadDownloads() {
			try {
				if (this.loading) return

				this.loading = true
				const { sortBy, sortDesc, page, itemsPerPage } = this.options

				const response = await this.axios.get('/downloads', {
					params: {
						page,
						limit: itemsPerPage,
						sortBy: sortBy[0],
						sortDesc: sortDesc[0],
						search: this.search,
					},
				})

				this.downloads = response.data.downloads
				this.total = response.data.total
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		closeDialogDownloads() {
			this.id = ''
			this.dialogDownloads = false
			this.loadDownloads()
		},
		openDialogDownloads() {
			this.dialogDownloads = true
		},
		async rowSelected(download) {
			this.dialogDownloads = true
			this.id = download.id
		},
	},
	watch: {
		options: {
			handler() {
				this.loadDownloads()
			},
			deep: true,
		},
		search() {
			this.loadDownloads()
		},
	},
}
</script>

<style>
.responsive-iframe {
	width: 100%;
	height: 700px;
}
</style>
