<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="4">
			<v-card elevation="12">
				<v-card-title>
					<AppLogo />
				</v-card-title>
				<v-card-text>
					<v-form>
						<v-text-field v-model="email" label="E-mail" prepend-icon="person" type="email" />

						<v-text-field
							v-model="senha"
							label="Senha"
							prepend-icon="lock"
							:type="visualizar ? 'text' : 'password'"
							:append-icon="visualizar ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="visualizar = !visualizar"
							@keypress.enter="login"
						/>
						<v-row align="center" justify="center">
							<v-progress-circular v-if="loading" color="primary" indeterminate />
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn v-if="!loading" to="Signup" color="primary" text>Se cadastrar</v-btn>
					<v-spacer />
					<v-btn v-if="!loading" color="primary" @click="login">Entrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import AppLogo from '@/components/widgets/AppLogo'
import { mapActions } from 'vuex'

export default {
	components: { AppLogo },
	data: function () {
		return {
			email: '',
			senha: '',
			visualizar: false,
			loading: false,
		}
	},
	methods: {
		...mapActions(['showError', 'addHierarquias']),
		async login() {
			try {
				this.loading = true
				if (!this.email || this.email === '') {
					throw 'Email inválido!'
				}
				if (!this.senha || this.senha === '') {
					throw 'Senha inválida!'
				}

				const response = await this.axios.post('/signin', { email: this.email, senha: this.senha })

				if (response.data.ativo === false) {
					throw new 'Usuário inativo!'()
				}
				
				this.$store.dispatch('login', response.data)
				await this.loadHierarquia()
				this.$router.push('/wiki')
			} catch (error) {
				//this.showError(error)
				this.showError('Usuário e/ou senha inválidos!')
			} finally {
				this.loading = false
			}
		},
		checkUser() {
			if (this.$store.getters.userAtivo) return this.$router.push('/')
		},
		async loadHierarquia() {
			try {
				this.loading = true

				const response = await this.axios.get('/hierarquias')

				this.addHierarquias(response.data.hierarquias)
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	mounted() {
		this.checkUser()
	},
}
</script>
