<template>
	<div>
		<ProjetoDialog :dialog="dialogProjeto" :id="projetoId" @dialogClose="dialogCloseProjeto" />
		<TopicoDialog :dialog="dialogTopico" :projetoId="projetoId" :id="topicoId" @dialogClose="dialogCloseTopico" />
		<DocumentoDialog
			:dialog="dialogDocumento"
			:topicoId="topicoId"
			:id="documentoId"
			:edicao="false"
			@dialogClose="dialogCloseDocumento"
		/>
		<DeletarDialog
			:abrirDialog="excluir"
			@dialogClose="dialogCloseDeletar"
			@deletar="deletar"
			:mensagem="mensagem"
		/>
		<v-card class="ma-3">
			<v-progress-linear v-if="projeto.loading" indeterminate color="primary" class="mt-5"></v-progress-linear>
			<div class="pa-3">
				<div class="itemProjeto mt-3">
					<div class="itemProjetoNome">{{ projeto.nome }}</div>
					<v-spacer />
					<BotoesEdicao
						v-if="manterDocumentos"
						:id="projeto.id"
						@adicionar="abrirDialogTopico({ idProjeto: projeto.id })"
						@editar="abrirDialogProjeto(projeto.id)"
						@excluir="abrirDialogDeletarProjeto(projeto)"
					/>
				</div>
				<div class="itemTopico ml-5">
					<v-list expand v-if="projeto.topico.length > 0">
						<draggable
							:disabled="!manterDocumentos"
							v-model="projeto.topico"
							group="topicos"
							class="list-group draggable"
							v-bind="dragOptions"
						>
							<transition-group type="transition">
								<template v-for="(topico, i) in projeto.topico">
									<div :key="topico.id" class="pa-0 ma-0">
										<v-list-group :key="i" v-if="topico.nome" color="black">
											<template slot="activator">
												<v-list-item-content>
													<v-list-item-title>
														<div class="itemProjeto">
															<div class="itemTopicoNome">{{ topico.nome }}</div>
															<v-spacer />
															<BotoesEdicao
																v-if="manterDocumentos"
																:id="topico.id"
																@adicionar="
																	abrirDialogDocumento({ idTopico: topico.id })
																"
																@editar="abrirDialogTopico({ idTopico: topico.id })"
																@excluir="abrirDialogDeletarTopico(topico)"
															/>
														</div>
													</v-list-item-title>
												</v-list-item-content>
											</template>
											<ListaDocumentos :topico="topico" @loadProjetos="loadProjetos" />
										</v-list-group>
									</div>
								</template>
							</transition-group>
						</draggable>
					</v-list>
					<div class="ml-5" v-else>
						<draggable
							:disabled="!manterDocumentos"
							v-model="projeto.topico"
							group="topicos"
							class="list-group"
							style="height: 70px"
							v-bind="dragOptions"
							@start="isDragging = true"
							@end="isDragging = false"
						>
							Projeto vazio
							<transition-group type="transition" name="flip-list"> </transition-group>
						</draggable>
					</div>
				</div>
			</div>
		</v-card>
	</div>
</template>

<script>
import BotoesEdicao from './BotoesEdicao.vue'
import ProjetoDialog from './ProjetoDialog.vue'
import TopicoDialog from './TopicoDialog.vue'
import DocumentoDialog from './Documentacao.vue'
import DeletarDialog from '../shared/DeletarDialog.vue'
import ListaDocumentos from './ListaDocumentos.vue'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

export default {
	props: { projeto: Object },
	components: {
		BotoesEdicao,
		TopicoDialog,
		DocumentoDialog,
		DeletarDialog,
		ProjetoDialog,
		draggable,
		ListaDocumentos,
	},
	data: function () {
		return {
			projetoId: '',
			topicoId: '',
			documentoId: '',
			dialogProjeto: false,
			loading: false,
			dialogTopico: false,
			dialogDocumento: false,
			mensagem: '',
			excluir: false,
		}
	},
	computed: {
		topicos() {
			return this.projeto.topico
		},
		...mapGetters(['manterDocumentos', 'userMaster']),
		dragOptions() {
			return {
				animation: 0,
				group: 'topicos',
				disabled: false,
				ghostClass: 'ghost',
			}
		},
	},
	methods: {
		async reorganizarTopicos(id, sequencia, projetoId) {
			if (this.manterDocumentos) {
				this.projeto.loading = true

				try {
					const response = await this.axios.put(`/topicos/${id}/sequencia`, {
						sequencia,
						projetoId,
					})

					if (response.status == 204) {
						this.$store.dispatch('showSuccess', 'Tópicos reorganizados com sucesso!')
					}
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.projeto.loading = false
					this.loadProjetos()
				}
			}
		},
		dialogCloseDeletar() {
			this.projeto.loading = true
			this.excluir = false
			this.projetoId = ''
			this.topicoId = ''
			this.documentoId = ''
			this.loadProjetos()
		},
		loadProjetos() {
			this.$emit('loadProjetos')
		},
		deletar() {
			if (this.projetoId) this.deleteProjeto()
			else if (this.topicoId) this.deleteTopico()
			else if (this.documentoId) this.deleteDocumento()
		},
		abrirDialogDeletarProjeto(projeto) {
			this.projetoId = projeto.id
			this.mensagem = `Você realmente deseja excluir o projeto ${projeto.nome}?`
			this.excluir = true
		},
		abrirDialogDeletarTopico(topico) {
			this.topicoId = topico.id
			this.mensagem = `Você realmente deseja excluir o tópico ${topico.nome}?`
			this.excluir = true
		},
		dialogCloseProjeto() {
			this.projeto.loading = true
			this.projetoId = ''
			this.dialogProjeto = false
			this.loadProjetos()
		},
		abrirDialogProjeto(id) {
			if (id) this.projetoId = id
			this.dialogProjeto = true
		},
		dialogCloseTopico() {
			this.projeto.loading = true
			this.projetoId = ''
			this.topicoId = ''
			this.dialogTopico = false
			this.loadProjetos()
		},
		abrirDialogTopico({ idTopico, idProjeto }) {
			if (idProjeto) this.projetoId = idProjeto
			if (idTopico) this.topicoId = idTopico
			this.dialogTopico = true
		},
		dialogCloseDocumento() {
			this.projeto.loading = true
			this.projetoId = ''
			this.topicoId = ''
			this.documentoId = ''
			this.dialogDocumento = false
			this.loadProjetos()
		},
		abrirDialogDocumento({ idDocumento, idTopico }) {
			if (idDocumento) this.documentoId = idDocumento
			else if (idTopico) this.topicoId = idTopico

			this.dialogDocumento = true
		},
		ler(link) {
			window.open(link)
		},
		async deleteProjeto() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/projetos/${this.projetoId}`)

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Projeto excluído com sucesso!')
					this.dialogCloseDocumento()
				}
			} catch (error) {
				console
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async deleteTopico() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/topicos/${this.topicoId}`)

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Tópico excluído com sucesso!')
					this.dialogCloseDocumento()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async deleteDocumento() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/documentos/${this.documentoId}`)

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Documento excluído com sucesso!')
					this.dialogCloseDocumento()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		async topicos() {
			if (this.projeto.topico.length > 0) {
				await this.projeto.topico.map(async (topico, id) => {
					if (topico.projetoId != this.projeto.id) {
						await this.reorganizarTopicos(topico.id, id + 1, this.projeto.id)
						topico.projetoId = this.projeto.id
					}

					if (topico.sequencia != id + 1) {
						await this.reorganizarTopicos(topico.id, id + 1, this.projeto.id)
						topico.sequencia = id + 1
					}
				})
			}
		},
	},
}
</script>


<style>
.itemBotoes {
	opacity: 0;
}
.itemBotoes:hover {
	opacity: 100;
}
.itemProjeto {
	width: 100%;
	display: flex;
	align-items: center;
}
.itemProjetoNome {
	font-size: 24px;
	text-transform: uppercase;
	font-weight: 500;
	color: #1976d2;
}
.itemTopicoNome {
	font-weight: bold;
}
.itemDocumentoLink:hover {
	text-decoration: underline;
}
.button {
	margin-top: 35px;
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
.list-group {
	min-height: 20px;
}
.list-group-item {
	cursor: move;
}
.list-group-item i {
	cursor: pointer;
}
</style>
