<template>
	<v-card>
		<HierarquiaDialog :dialog="dialogHierarquia" :id="id" @dialogClose="closeDialogHierarquia()" />
		<v-card-title> <v-icon left color="primary">mdi-account-group</v-icon>Hierarquia</v-card-title>
		<v-card-text v-if="userMaster">
			<v-row class="pa-0 ma-0" align="center" justify="center">
				<v-col cols="3">
					<draggable
						v-model="hierarquias"
						group="hierarquias"
						v-bind="dragOptions"
						@start="isDragging = true"
						@end="isDragging = false"
						:move="mover"
                        @change="reorganizarHierarquia"
					>
						<transition-group type="transition">
							<template v-for="(hierarquia, i) in this.hierarquias">
								<v-card :key="i" class="pa-3 mb-2">
									<v-row align="center" class="pa-0 ma-0">
										<span class="nome ml-3">{{ hierarquia.nome }}</span>
										<v-spacer />
										<v-btn text @click="editar(hierarquia.id)" :disabled="hierarquia.nivel < 2"
											><v-icon small>mdi-pencil</v-icon></v-btn
										>
									</v-row>
								</v-card>
							</template>
						</transition-group>
					</draggable>
					<v-btn width="100%" color="primary" v-if="!edicao" @click="openDialogHierarquia">Criar novo grupo</v-btn>
                    <v-btn width="100%" color="success"  v-if="edicao" @click="salvar" class="mb-2">Salvar</v-btn>
                    <v-btn width="100%" color="error"  v-if="edicao" @click="cancelar">Cancelar</v-btn>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import HierarquiaDialog from '../components/hierarquia/HierarquiaDialog.vue'

export default {
	components: { draggable, HierarquiaDialog },
	data: function () {
		return {
			hierarquias: [],
			loading: false,
			id: '',
			dialogHierarquia: false,
			edicao: false,
		}
	},
	computed: {
		...mapGetters(['userMaster']),
		dragOptions() {
			return {
				animation: 0,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			}
		},
	},
	methods: {
		mover(e) {
			return !(e.draggedContext.element.fixed || e.draggedContext.futureIndex <= 1)
		},
        reorganizarHierarquia(){
            this.edicao = true
        },
		async loadHierarquia() {
			this.hierarquias = await this.retornarHierarquia(this.axios)
		},
		closeDialogHierarquia() {
			this.id = ''
			this.dialogHierarquia = false
			this.loadHierarquia()
		},
		openDialogHierarquia() {
			this.dialogHierarquia = true
		},
		async editar(id) {
			this.dialogHierarquia = true
			this.id = id
		},
        async salvar(){
            this.loading = true
            try {
				const response = await this.axios.put(`/hierarquias`, {
					hierarquias: this.hierarquias,
				})

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Hierarquia reorganizada com sucesso!')
                    this.edicao = false
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
				this.loadHierarquia()
			}

        },
        cancelar(){
            this.loadHierarquia()
            this.edicao = false
        }
	},
	async mounted() {
		this.loading = true
		await this.loadHierarquia()
		this.loading = false
	},
}
</script>

<style scoped>
.nome {
	font-weight: 500;
}
</style>