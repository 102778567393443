import Vue from 'vue'
import VueRouter from 'vue-router'

import Signin from '../views/Signin.vue'
import Signup from '../views/Signup.vue'
import Usuarios from '../views/Usuarios.vue'
/*import PxAcerto from '../views/PxAcerto.vue'
import Ajuda from '../views/Ajuda.vue'*/
import Wiki from '../views/Wiki.vue'
import Downloads from '../views/Downloads.vue'
import Hierarquia from '../views/Hierarquia'

Vue.use(VueRouter)

const routes = [
	{
		path: '*',
		component: Wiki
	},

	{
		path: '/signin',
		name: 'Signin',
		component: Signin
	},
	{
		path: '/signup',
		name: 'Signup',
		component: Signup
	},
	{
		path: '/usuarios',
		name: 'Usuarios',
		component: Usuarios
	},
	/*{
		path: '/pxacerto',
		name: 'PxAcerto',
		component: PxAcerto
	},
	{
		path: '/ajuda',
		name: 'Ajuda',
		component: Ajuda
	},*/
	{
		path: '/wiki',
		name: 'Wiki',
		component: Wiki
	},
	{
		path: '/Downloads',
		name: 'Downloads',
		component: Downloads
	},
	{
		path: '/Hierarquia',
		name: 'Hierarquia',
		component: Hierarquia
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
export default router
