<template>
	<v-dialog v-model="dialogValue" max-width="1024" scrollable>
		<v-card>
			<v-card-title>
				<v-icon left>lock</v-icon>Cadastro de Usuário
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-text-field
					v-model="usuario.nome"
					label="Nome"
					prepend-icon="person"
					type="text"
					required
					:disabled="loading"
				/>
				<v-select
					prepend-icon="location_city"
					label="Cidade"
					v-model="usuario.cidade"
					:items="cidades"
					item-text="nome"
					item-value="nome"
					:disabled="loading"
				></v-select>
				<v-select
					prepend-icon="mdi-account-group"
					label="Grupo"
					v-model="usuario.hierarquiaId"
					:items="hierarquias"
					item-text="nome"
					item-value="id"
					:disabled="loading"
				></v-select>
				<v-text-field
					v-model="usuario.email"
					label="E-mail"
					prepend-icon="email"
					type="email"
					required
					:disabled="loading"
				/>
				<v-row v-if="!usuario.id">
					<v-col>
						<v-text-field
							v-model="senha"
							label="Senha"
							prepend-icon="lock"
							:type="show ? 'text' : 'password'"
							required
							:disabled="loading"
						>
							<template v-slot:append>
								<v-icon color="primary" @click="show = !show">
									{{ show ? 'mdi-eye' : 'mdi-eye-off' }}
								</v-icon>
							</template>
						</v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="confirmaSenha"
							label="Confirmação Senha"
							:prepend-icon="senha === confirmaSenha ? 'lock' : 'lock'"
							:type="show2 ? 'text' : 'password'"
							required
							:disabled="loading"
						>
							<template v-slot:append>
								<v-icon color="primary" @click="show2 = !show2">
									{{ show2 ? 'mdi-eye' : 'mdi-eye-off' }}
								</v-icon>
							</template>
						</v-text-field>
					</v-col>
				</v-row>
				<v-row v-if="usuario.id">
					<v-col cols="12" sm="6" md="3" v-if="manterUsuarios">
						<v-checkbox
							label="Ativo"
							v-model="ativo"
							:disabled="loading"
							hide-details=""
							class="ma-0"
						></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6" md="3" v-if="manterUsuarios">
						<v-checkbox
							label="Manter Usuários"
							v-model="mantemUsuarios"
							:disabled="loading"
							hide-details=""
							class="ma-0"
						></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6" md="3" v-if="manterUsuarios">
						<v-checkbox
							label="Manter Documentos"
							v-model="mantemDocumentos"
							:disabled="loading"
							hide-details=""
							class="ma-0"
						></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6" md="3" v-if="manterUsuarios">
						<v-checkbox
							label="Manter Downloads"
							v-model="mantemDownloads"
							:disabled="loading"
							hide-details=""
							class="ma-0"
						></v-checkbox>
					</v-col>
					
					<v-col cols="6" v-if="userMaster">
						<v-btn color="primary" @click="alterarSenha()"> Alterar senha </v-btn>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
				<v-spacer v-if="!loading"></v-spacer>
				<v-btn v-if="!loading" @click="dialogClose">Sair</v-btn>
				<v-btn color="primary" v-if="!loading" @click="salvar">Salvar</v-btn>
			</v-card-actions>
			<RedefinirSenha :abrirDialog="abrir" @dialogClose="closeAlterarSenha" :id="usuario.id" tipo="usuario" />
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import RedefinirSenha from './RedefinirSenha.vue'

export default {
	props: {
		id: String,
		dialog: Boolean,
	},
	data: function () {
		return {
			loading: false,
			cidades: [
				{ uf: 'GO', nome: 'Jataí' },
				{ uf: 'GO', nome: 'Rio Verde' },
				{ uf: 'TO', nome: 'Palmas' },
			],
			abrir: false,
			senha: '',
			confirmaSenha: '',
			show: false,
			show2: false,
			usuario: {},
			hierarquias: [],
		}
	},
	components: { RedefinirSenha },
	computed: {
		...mapGetters(['userAdmin', 'userMaster', 'manterUsuarios']),
		dialogValue: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
		ativo: {
			get() {
				return this.usuario.ativo
			},
			set(val) {
				this.usuario.ativo = val
				this.setAtivo()
			},
		},
		mantemUsuarios: {
			get() {
				return this.usuario.manterUsuarios
			},
			set(val) {
				this.usuario.manterUsuarios = val
				this.setManterUsuarios()
			},
		},
		mantemDocumentos: {
			get() {
				return this.usuario.manterDocumentos
			},
			set(val) {
				this.usuario.manterDocumentos = val
				this.setManterDocumentos()
			},
		},
		mantemDownloads: {
			get() {
				return this.usuario.manterDownloads
			},
			set(val) {
				this.usuario.manterDownloads = val
				this.setManterDownloads()
			},
		},
	},
	methods: {
		async loadUsuario() {
			try {
				this.loading = true
				const response = await this.axios.get(`/usuarios/${this.id}`)
				this.usuario = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async salvar() {
			if (this.usuario.id) {
				this.editar()
			} else {
				this.criar()
			}
		},
		async criar() {
			if (!(this.senha == this.confirmaSenha)) this.$store.dispatch('showError', 'As senhas não estão iguais!')
			else {
				try {
					this.loading = true
					await this.axios.post(`/usuarios`, {
						nome: this.usuario.nome,
						cidade: this.usuario.cidade,
						email: this.usuario.email,
						senha: this.senha,
						hierarquiaId: this.usuario.hierarquiaId
					})
					this.$store.dispatch('showSuccess', 'Usuário criado com sucesso!')
					this.$emit('dialogClose')
					this.$emit('closeModal')
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loading = false
				}
			}
		},
		async editar() {
			try {
				this.loading = true
				await this.axios.put(`/usuarios/${this.usuario.id}`, this.usuario)
				this.$store.dispatch('showSuccess', 'Usuário alterado com sucesso')
				this.$emit('dialogClose')
				this.$emit('closeModal')
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async setAtivo() {
			await this.setField('ativo', 'Ativo alterado com sucesso!')
		},
		async setManterUsuarios() {
			await this.setField('manterUsuarios', 'Manter usuários alterado com sucesso!')
		},
		async setManterDocumentos() {
			await this.setField('manterDocumentos', 'Manter documentos alterado com sucesso!')
		},
		async setManterDownloads() {
			await this.setField('manterDownloads', 'Manter downloads alterado com sucesso!')
		},
		async setField(field, msg) {
			try {
				this.loading = true
				const params = {}
				params[field] = this.usuario[field]
				await this.axios.put(`/usuarios/${this.usuario.id}/${field}`, params)
				this.$store.dispatch('showSuccess', msg)
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		dialogClose() {
			this.usuario = {}
			this.$emit('dialogClose')
		},
		alterarSenha() {
			this.abrir = true
		},
		closeAlterarSenha() {
			this.abrir = false
		},
	},
	watch: {
		async dialog() {
			if (this.dialog) {
				this.hierarquias = await this.retornarHierarquia(this.axios)

				if (this.id) {
					this.loadUsuario()
				}
			}
		},
	},
}
</script>