<template>
	<v-btn :text="value ? false : true" :color="value ? 'primary' : 'black'" @click="funcao()">
		<v-icon>
			{{this.icon}}
		</v-icon>
	</v-btn>
</template>

<script>
export default {
	props: {
		value: Boolean,
		icon: String,
	},
	methods: {
		funcao() {
			this.$emit('funcao')
		},
	},
}
</script>