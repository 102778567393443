import convertDate from './convertDate'
import convertTime from './convertTime'
import retornarHierarquia from './retornarHierarquia'

export default {
	convertDate,
	convertTime,
	verificaLetra(e) {
		let char = String.fromCharCode(e.keyCode)
		if (/^[0-9]+$/.test(char)) return true
		else e.preventDefault()
	},
	retornarHierarquia
}
