<template>
	<v-dialog v-model="dialogValue" max-width="500px" persistent>
		<DialogPersonalizado titulo="Alterar Senha" icone="mdi-lock" @dialogClose="dialogClose">
			Senha atual
			<v-text-field
				solo
				placeholder="Senha atual"
				v-model="senha.atual"
				@click:append="show = !show"
				:type="show ? 'text' : 'password'"
				class="mb-3"
				hide-details
			>
				<template v-slot:append>
					<v-icon color="primary" @click="show = !show">
						{{ show ? 'mdi-eye' : 'mdi-eye-off' }}
					</v-icon>
				</template>
			</v-text-field>
			Nova senha
			<v-text-field
				solo
				placeholder="Nova senha"
				v-model="senha.nova"
				@click:append="show2 = !show2"
				:type="show2 ? 'text' : 'password'"
				class="mb-3"
				hide-details
			>
				<template v-slot:append>
					<v-icon color="primary" @click="show2 = !show2">
						{{ show2 ? 'mdi-eye' : 'mdi-eye-off' }}
					</v-icon>
				</template>
			</v-text-field>
			Confirmar nova senha
			<v-text-field
				solo
				placeholder="Confirmação de nova senha"
				v-model.lazy="senha.confirmada"
				@click:append="show3 = !show3"
				:type="show3 ? 'text' : 'password'"
				class="mb-3"
				hide-details
			>
				<template v-slot:append>
					<v-icon color="primary" @click="show3 = !show3">
						{{ show3 ? 'mdi-eye' : 'mdi-eye-off' }}
					</v-icon>
				</template>
			</v-text-field>
			<v-btn class="white--text mt-2" @click="salvar()" color="primary" large elevation="9" width="100%">
				Salvar
			</v-btn>
		</DialogPersonalizado>
	</v-dialog>
</template>

<script>
import DialogPersonalizado from '../shared/DialogPersonalizado.vue'
import {mapState, mapGetters } from 'vuex'

export default {
	props: {
		abrirDialog: Boolean,
		id: String,
	},
	components: {
		DialogPersonalizado,
	},
	data() {
		return {
			show: false,
			show2: false,
			show3: false,
			senha: {},
			loading: false,
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState('usuarios', ['usuario', 'dialog']),
		dialogValue: {
			get() {
				return this.abrirDialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.senha = {}
			this.show = this.show2 = this.show3 = false
			this.$emit('dialogClose')
		},
		async salvar() {
			try {
				this.loading = true
				const response = await this.axios.put(`/usuarios/${this.id}/alterarsenha`, {
					senha: this.senha,
				})

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Senha alterada com sucesso!')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>
