import Vue from 'vue'
import Vuex from 'vuex'

import moduleUsuario from './modules/usuarios'
import moduleHierarquias from './modules/hierarquias'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		drawer: true,
		alert: {}
	},
	mutations: {
		toggleDrawer(state) {
			state.drawer = !state.drawer
		},
		setDrawer(state, drawer) {
			state.drawer = drawer
		},
		setAlert(state, alert) {
			state.alert = alert
		}
	},
	actions: {
		showError({ commit }, error) {
			let msg = ''
			if (typeof error === 'string') msg += error
			else if (error.response.data.detalhes) {
				msg += error.response.data.detalhes
			} else if (error.response.data.msg) {
				msg += error.response.data.msg
			} else {
				msg += error.message
			}
			commit('setAlert', { color: 'error', msg, visible: true })
		},
		showSuccess({ commit }, msg) {
			commit('setAlert', { color: 'success', msg, visible: true })
		}
	},
	modules: {
		moduleUsuario,
		moduleHierarquias
	}
})

export default store
