<template>
	<div>
		<v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app>
			<v-toolbar-title style="width: 300px" class="ml-0 pl-4 pr-0">
				<v-app-bar-nav-icon @click.stop="toggleDrawer" />
				<AppLogo />
			</v-toolbar-title>
			<v-spacer />
			<v-menu left bottom offset-y>
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on" large>
						<v-icon>account_circle</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item class="userPanel">
						<v-list-item-icon>
							<v-icon>perm_identity</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ user.nome }}</v-list-item-title>
					</v-list-item>
					<v-divider />
					<v-list-item @click="alterarSenha()">
						<v-list-item-icon>
							<v-icon>mdi-cog-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Alterar senha</v-list-item-title>
					</v-list-item>
					<v-divider />
					<v-list-item @click="logout">
						<v-list-item-icon>
							<v-icon color="red">exit_to_app</v-icon>
						</v-list-item-icon>
						<v-list-item-content>Sair</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		<AlterarSenha :abrirDialog="abrir" tipo="usuario" @dialogClose="dialogClose" :id="user.id" />
	</div>
</template>
<script>
import AppLogo from '../widgets/AppLogo'
import AlterarSenha from '../../components/usuarios/AlterarSenha.vue'
import { mapMutations, mapGetters } from 'vuex'

export default {
	components: { AppLogo, AlterarSenha },
	data() {
		return {
			abrir: false,
		}
	},
	methods: {
		...mapMutations(['toggleDrawer']),
		logout() {
			this.$store.dispatch('logout')
			this.$router.push('/signin')
		},
		alterarSenha() {
			this.abrir = true
		},
		dialogClose() {
			this.abrir = false
		},
	},
	computed: {
		...mapGetters({user: 'user'}),
	},
}
</script>
<style scoped>
.userPanel {
	min-width: 250px;
}
</style>