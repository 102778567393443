<template>
	<div>
		<DeletarDialog
			:abrirDialog="excluir"
			@dialogClose="dialogCloseDeletar"
			@deletar="deletar"
			mensagem="Você realmente deseja excluir esse link?"
		/>
		<v-dialog v-model="topicoDialog" max-width="500" scrollable>
			<v-card>
				<v-card-title>
					<v-icon left color="primary">mdi-link</v-icon>Link
					<v-spacer></v-spacer>
					<v-btn icon @click="dialogClose">
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col>
							<v-text-field
								label="Link"
								v-model="linkNovo"
								:readonly="!userAdmin"
								autocomplete="off"
							></v-text-field>
						</v-col>
					</v-row>

					<div>
						<v-row v-if="userAdmin" justify="end">
							<v-btn v-if="linkNovo" class="mx-1" color="error" @click="abrirDialogDeletar">
								<v-icon left>mdi-delete</v-icon>
								deletar
							</v-btn>
							<v-btn class="mx-1" color="success" @click="salvar">
								<v-icon left>mdi-content-save</v-icon>
								Salvar
							</v-btn>
						</v-row>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DeletarDialog from '../shared/DeletarDialog.vue'

export default {
	props: {
		linkAntigo: String,
		dialog: Boolean,
	},
	components: { DeletarDialog },
	data: function () {
		return {
			linkNovo: '',
			loading: false,
			excluir: false,
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		topicoDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
			this.linkNovo = ''
		},
		dialogCloseDeletar() {
			this.excluir = false
		},
		dialogCloseSolicitacoes() {
			this.dialogSolicitacoes = false
			this.loadSolicitacoes()
		},
		async loadLink() {
			if (this.linkAntigo) {
				this.linkNovo = this.linkAntigo
			}
		},
		async salvar() {
			this.$emit('salvarlink', this.linkNovo)
			this.dialogClose()
		},
		abrirDialogDeletar() {
			this.excluir = true
		},
		async deletar() {
			this.$emit('salvarlink', '')
			this.dialogClose()
			
		},
	},
	watch: {
		dialog() {
			this.loadLink()
		},
	},
}
</script>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
</style>
