<template>
	<div>
		<ProjetoDialog :dialog="dialogProjeto" :id="projetoId" @dialogClose="dialogCloseProjeto" />
		<TopicoDialog :dialog="dialogTopico" :projetoId="projetoId" :id="topicoId" @dialogClose="dialogCloseTopico" />
		<DocumentoDialog
			:dialog="dialogDocumento"
			:topicoId="topicoId"
			:id="documentoId"
			:edicao="false"
			@dialogClose="dialogCloseDocumento"
		/>
		<DeletarDialog
			:abrirDialog="excluir"
			@dialogClose="dialogCloseDeletar"
			@deletar="deletar"
			:mensagem="mensagem"
		/>
		<v-card class="pb-5">
			<v-card-title> <v-icon left color="primary">mdi-book-open-blank-variant</v-icon>Wiki </v-card-title>
			<v-btn color="primary" @click="abrirDialogProjeto()" class="ml-9 mb-4" v-if="manterDocumentos"
				>Criar Projeto</v-btn
			>
			<v-row class="ml-5 mr-5">
				<v-col class="pa-0">
					<draggable
						:disabled="!manterDocumentos"
						v-model="primeiraListaProjetos"
						group="projetos"
						class="list-group"
						@change="reorganizarProjetos"
						v-bind="dragOptions"
						@start="isDragging = true"
						@end="isDragging = false"
					>
						<transition-group type="transition">
							<template v-for="(projeto, i) in this.primeiraListaProjetos">
								<ProjetoCard :key="i" :projeto="projeto" @loadProjetos="loadProjetos()" />
							</template>
						</transition-group>
					</draggable>
				</v-col>
				<v-col class="pa-0">
					<draggable
						:disabled="!manterDocumentos"
						v-model="segundaListaProjetos"
						group="projetos"
						@change="reorganizarProjetos"
						v-bind="dragOptions"
						@start="isDragging = true"
						@end="isDragging = false"
					>
						<transition-group type="transition">
							<template v-for="(projeto, i) in this.segundaListaProjetos">
								<ProjetoCard :key="i" :projeto="projeto" @loadProjetos="loadProjetos()" />
							</template>
						</transition-group>
					</draggable>
				</v-col>
			</v-row>
			<v-progress-linear v-if="loading" indeterminate color="primary" class="mt-5"></v-progress-linear>
			<div class="masonry" v-else></div>
		</v-card>
	</div>
</template>
<script>
import ProjetoDialog from '../components/wiki/ProjetoDialog.vue'
import TopicoDialog from '../components/wiki/TopicoDialog.vue'
import DocumentoDialog from '../components/wiki/Documentacao.vue'
import DeletarDialog from '../components/shared/DeletarDialog.vue'
import ProjetoCard from '../components/wiki/ProjetoCard.vue'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

export default {
	components: { ProjetoDialog, TopicoDialog, DocumentoDialog, DeletarDialog, ProjetoCard, draggable },
	data: function () {
		return {
			projetos: [],
			primeiraListaProjetos: [],
			segundaListaProjetos: [],
			projetoId: '',
			topicoId: '',
			documentoId: '',
			dialogProjeto: false,
			loading: false,
			dialogTopico: false,
			dialogDocumento: false,
			mensagem: '',
			excluir: false,
		}
	},
	computed: {
		...mapGetters(['manterDocumentos', 'userMaster', 'userNivel', 'userNivel']),
		dragOptions() {
			return {
				animation: 0,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			}
		},
	},
	methods: {
		async reorganizarProjetos() {
			this.loading = true

			this.projetos = []

			this.primeiraListaProjetos.map((proejto) => {
				this.projetos.push({ id: proejto.id })
			})

			this.segundaListaProjetos.map((proejto) => {
				this.projetos.push({ id: proejto.id })
			})
			try {
				const response = await this.axios.put(`/projetos`, {
					projetos: this.projetos,
				})

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Projetos reorganizados com sucesso!')
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
				this.loadProjetos()
			}
		},
		async loadProjetos() {
			this.loading = true

			try {
				const response = await this.axios.get('/projetos')

				this.primeiraListaProjetos = response.data.primeiraListaProjetos
				this.segundaListaProjetos = response.data.segundaListaProjetos
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		dialogCloseDeletar() {
			this.excluir = false
			this.projetoId = ''
			this.topicoId = ''
			this.documentoId = ''
			this.loadProjetos()
		},
		deletar() {
			if (this.projetoId) this.deleteProjeto()
			else if (this.topicoId) this.deleteTopico()
			else if (this.documentoId) this.deleteDocumento()
		},
		abrirDialogDeletarProjeto(id) {
			this.projetoId = id
			this.mensagem = 'Você realmente deseja excluir esse projeto?'
			this.excluir = true
		},
		abrirDialogDeletarTopico(id) {
			this.topicoId = id
			this.mensagem = 'Você realmente deseja excluir esse tópico?'
			this.excluir = true
		},
		abrirDialogDeletarDocumento(id) {
			this.documentoId = id
			this.mensagem = 'Você realmente deseja excluir esse documento?'
			this.excluir = true
		},
		dialogCloseProjeto() {
			this.projetoId = ''
			this.dialogProjeto = false
			this.loadProjetos()
		},
		abrirDialogProjeto(id) {
			if (id) this.projetoId = id
			this.dialogProjeto = true
		},
		dialogCloseTopico() {
			this.projetoId = ''
			this.topicoId = ''
			this.dialogTopico = false
			this.loadProjetos()
		},
		abrirDialogTopico({ idTopico, idProjeto }) {
			if (idProjeto) this.projetoId = idProjeto
			if (idTopico) this.topicoId = idTopico
			this.dialogTopico = true
		},
		dialogCloseDocumento() {
			this.projetoId = ''
			this.topicoId = ''
			this.documentoId = ''
			this.dialogDocumento = false
			this.loadProjetos()
		},
		abrirDialogDocumento({ idDocumento, idTopico }) {
			if (idDocumento) this.documentoId = idDocumento
			else if (idTopico) this.topicoId = idTopico

			this.dialogDocumento = true
		},
		ler(link) {
			window.open(link)
		},
		retornarClasseItem(i) {
			if ((i / 2) % 2 == 0) return 'item'
			else return 'item right'
		},
		async deleteProjeto() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/projetos/${this.projetoId}`)

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Projeto excluído com sucesso!')
					this.dialogCloseDocumento()
				}
			} catch (error) {
				console
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async deleteTopico() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/topicos/${this.topicoId}`)

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Tópico excluído com sucesso!')
					this.dialogCloseDocumento()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async deleteDocumento() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/documentos/${this.documentoId}`)

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Documento excluído com sucesso!')
					this.dialogCloseDocumento()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	mounted() {
		this.loadProjetos()
	},
}
</script>

<style>
.button {
	margin-top: 35px;
}
.flip-list-move {
	transition: transform 0.5s;
}
.no-move {
	transition: transform 0s;
}
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
.list-group {
	min-height: 20px;
}
.list-group-item {
	cursor: move;
}
.list-group-item i {
	cursor: pointer;
}
</style>