<template>
	<div>
		<DocumentoDialog
			:dialog="dialogDocumento"
			:topicoId="topicoId"
			:id="documentoId"
			:edicao="false"
			@dialogClose="dialogCloseDocumento"
		/>
		<DeletarDialog
			:abrirDialog="excluir"
			@dialogClose="dialogCloseDeletar"
			@deletar="deletar"
			:mensagem="mensagem"
		/>
		<div class="ml-5" v-if="topico.documento.length > 0">
			<draggable
				:disabled="!manterDocumentos"
				v-model="topico.documento"
				group="documentos"
				class="list-group"
				v-bind="dragOptions"
				@start="isDragging = true"
				@end="isDragging = false"
			>
				<transition-group type="transition" name="flip-list">
					<template v-for="(documento, j) in topico.documento">
						<v-list-item :key="j" dense>
							<div class="itemProjeto pa-0 ma-0" :key="documento.id">
								<div
									@click="
										abrirDialogDocumento({
											idDocumento: documento.id,
										})
									"
									class="itemDocumentoLink"
								>
									{{ documento.titulo }}
								</div>
								<v-spacer />
								<BotoesEdicao
									v-if="manterDocumentos"
									:id="documento.id"
									:adiciona="false"
									@editar="
										abrirDialogDocumento({
											idDocumento: documento.id,
										})
									"
									@excluir="abrirDialogDeletarDocumento(documento)"
								/>
							</div>
						</v-list-item>
					</template>
				</transition-group>
			</draggable>
		</div>
		<div class="ml-5" v-else>
			<draggable
				:disabled="!manterDocumentos"
				v-model="topico.documento"
				group="documentos"
				class="list-group"
				style="height: 70px"
				v-bind="dragOptions"
				@start="isDragging = true"
				@end="isDragging = false"
			>
				Tópico vazio
				<transition-group type="transition" name="flip-list"> </transition-group>
			</draggable>
		</div>
	</div>
</template>
<script>
import draggable from 'vuedraggable'
import BotoesEdicao from './BotoesEdicao.vue'
import DeletarDialog from '../shared/DeletarDialog.vue'
import DocumentoDialog from './Documentacao.vue'
import { mapGetters } from 'vuex'

export default {
	props: { topico: Object },
	components: { BotoesEdicao, draggable, DeletarDialog, DocumentoDialog },
	computed: {
		documentos() {
			return this.topico.documento
		},
		...mapGetters(['manterDocumentos', 'userMaster']),
		dragOptions() {
			return {
				animation: 0,
				group: 'documentos',
				disabled: false,
				ghostClass: 'ghost',
			}
		},
	},
	data: function () {
		return {
			topicoId: '',
			documentoId: '',
			loading: false,
			dialogDocumento: false,
			mensagem: '',
			excluir: false,
		}
	},
	methods: {
		async reorganizarDocumentos(id, sequencia, topicoId) {
			if (this.manterDocumentos) {
				try {
					const response = await this.axios.put(`/documentos/${id}/sequencia`, {
						sequencia,
						topicoId,
					})

					if (response.status == 204) {
						this.$store.dispatch('showSuccess', 'Documentos reorganizados com sucesso!')
					}
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loadProjetos()
				}
			}
		},
		abrirDialogDeletarDocumento(documento) {
			this.documentoId = documento.id
			this.mensagem = `Você realmente deseja excluir o documento ${documento.titulo}?`
			this.excluir = true
		},
		deletar() {
			if (this.documentoId) this.deleteDocumento()
		},
		dialogCloseDocumento() {
			this.documentoId = ''
			this.dialogDocumento = false
			this.loadProjetos()
		},
		loadProjetos() {
			this.$emit('loadProjetos')
		},
		abrirDialogDocumento({ idDocumento, idTopico }) {
			if (idDocumento) this.documentoId = idDocumento
			else if (idTopico) this.topicoId = idTopico

			this.dialogDocumento = true
		},
		async deleteDocumento() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/documentos/${this.documentoId}`)

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Documento excluído com sucesso!')
					this.dialogCloseDocumento()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		dialogCloseDeletar() {
			this.excluir = false
			this.documentoId = ''
			this.loadProjetos()
		},
	},

	watch: {
		async documentos() {
			if (this.topico.documento.length > 0) {
				await this.topico.documento.map(async (documento, id) => {
					if (documento.topicoId != this.topico.id) {
						await this.reorganizarDocumentos(documento.id, id + 1, this.topico.id)
						documento.topicoId = this.topico.id
					}
					if (documento.sequencia != id + 1) {
						await this.reorganizarDocumentos(documento.id, id + 1, this.topico.id)
						documento.sequencia = id + 1
					}
				})
			}
		},
	},
}
</script>