<template>
	<div class="itemBotoes" v-if="id">
		<v-btn x-small text plain @click="adicionar()" v-if="adiciona">
			<v-icon color="success" dense> mdi-plus </v-icon>
		</v-btn>
		<v-btn x-small text plain @click="editar()" v-if="edita">
			<v-icon color="primary" small> mdi-pencil </v-icon>
		</v-btn>
		<v-btn x-small text plain @click="excluir()" v-if="exclui">
			<v-icon color="error" small> mdi-delete </v-icon>
		</v-btn>
	</div>
</template>

<script>
export default {
	props: {
		id: String,
		adiciona: {
			type: Boolean,
			default: true,
		},
		edita: {
			type: Boolean,
			default: true,
		},
		exclui: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		adicionar() {
			this.$emit('adicionar', this.id)
		},
		editar() {
			this.$emit('editar', this.id)
		},
		excluir() {
			this.$emit('excluir', this.id)
		},
	},
}
</script>

<style>
.itemBotoes {
	opacity: 0;
}
.itemBotoes:hover {
	opacity: 100;
}
</style>
