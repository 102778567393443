<template>
	<v-dialog v-model="dialog" width="400">
		<v-card width="400" class="pa-4">
			<v-card-text class="ma-1 pa-1 font-weight-medium">
				{{ mensagem }}
			</v-card-text>
			<v-row>
				<v-col cols="6">
					<v-btn class="mx-1" color="error" width="100%" @click="deletar()"> Sim </v-btn>
				</v-col>
				<v-col cols="6">
					<v-btn class="mx-1" color="error" text @click="dialogClose()" width="100%"> Não </v-btn>
				</v-col>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		abrirDialog: Boolean,
		id: String,
		mensagem: String,
	},
	computed: {
		dialog: {
			get() {
				return this.abrirDialog
			},
			set() {
				this.dialogClose()
				this.loadEmpresas()
			},
		},
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
		async deletar() {
			this.$emit('deletar')
			this.dialogClose()
		},
	},
}
</script>


