<template>
	<div>
		<DeletarDialog
			:abrirDialog="excluir"
			@dialogClose="dialogCloseDeletar"
			@deletar="deletar"
			mensagem="Você realmente deseja excluir esse tópico?"
		/>
		<v-dialog v-model="topicoDialog" max-width="500" scrollable>
			<v-card>
				<v-card-title>
					<v-icon left>mdi-tune</v-icon>Cadastro de Tópico
					<v-tooltip bottom v-if="topico.id">
						<template v-slot:activator="{ on }">
							<v-icon right color="primary" v-on="on">mdi-identifier</v-icon>
						</template>
						<span>{{ topico.id }}</span>
					</v-tooltip>
					<v-spacer></v-spacer>
					<v-btn icon @click="dialogClose">
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col>
							<v-text-field
								label="Nome"
								v-model="topico.nome"
								:readonly="!manterDocumentos"
								hide-details
								autocomplete="off"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-select
								label="Grupo"
								v-model="topico.hierarquiaId"
								:readonly="!manterDocumentos"
								:items="hierarquias"
								item-text="nome"
								item-value="id"
								:disabled="loading"
							></v-select>
						</v-col>
					</v-row>
					<div>
						<v-row v-if="manterDocumentos" justify="end">
							<v-btn v-if="id" class="mx-1" color="error" @click="abrirDialogDeletar">
								<v-icon left>mdi-delete</v-icon>
								deletar
							</v-btn>
							<v-btn class="mx-1" color="success" @click="salvar">
								<v-icon left>mdi-content-save</v-icon>
								Salvar
							</v-btn>
						</v-row>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DeletarDialog from '../shared/DeletarDialog.vue'

export default {
	props: {
		id: String,
		projetoId: String,
		dialog: Boolean,
	},
	components: { DeletarDialog },
	data: function () {
		return {
			topico: {},
			loading: false,
			excluir: false,
			hierarquias: [],
		}
	},
	computed: {
		...mapGetters(['manterDocumentos', 'userMaster', 'userNivel']),
		...mapState(['user']),
		topicoDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.topico = {}

			this.$emit('dialogClose')
		},
		dialogCloseDeletar() {
			this.excluir = false
		},
		dialogCloseSolicitacoes() {
			this.dialogSolicitacoes = false
			this.loadSolicitacoes()
		},
		async loadTopico() {
			if (this.id) {
				try {
					this.loading = true
					const response = await this.axios.get(`/topicos/${this.id}`)
					this.topico = response.data.topico
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loading = false
				}
			}
		},
		async salvar() {
			if (!this.id) {
				try {
					this.loading = true
					const response = await this.axios.post(`/projetos/${this.projetoId}/topico`, {
						nome: this.topico.nome,
						hierarquiaId: this.topico.hierarquiaId
					})

					if (response.status == 201) {
						this.dialogClose()
						this.$store.dispatch('showSuccess', response.data.msg)
					} else if (response.status == 202) {
						this.$store.dispatch('showError', response.data.msg)
					}
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loading = false
				}
			} else {
				try {
					this.loading = true
					const response = await this.axios.put(`/topicos/${this.id}`, {
						nome: this.topico.nome,
						hierarquiaId: this.topico.hierarquiaId
					})

					if (response.status == 204) {
						this.$store.dispatch('showSuccess', 'O cadastro foi alterado com sucesso!')
						this.dialogClose()
					}
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loading = false
				}
			}
		},
		abrirDialogDeletar() {
			this.excluir = true
		},
		async deletar() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/topicos/${this.id}`)
				this.topico = response.data.topico
				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Cadastro de ajuda foi deletado com sucesso')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		async dialog() {
			if (this.dialog) {
				this.hierarquias = await this.retornarHierarquia(this.axios, this.userNivel)

				if (this.id) {
					this.loadTopico()
				}
			}
		},
	},
}
</script>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
</style>
