import Vue from 'vue'
import store from '..'

const moduleUsuario = {
	state: {
		user: {},
		usuario: {}
	},
	getters: {
		user: state => { return state.user },
		usuario: state => { return state.usuario },
		userAtivo: state => {
			return state.user !== null && state.user.ativo === true
		},
		userAdmin: state => {
			return state.user !== null && state.user.nivel <= store.getters.administracao
		},
		userMaster: state => {
			return state.user !== null && state.user.nivel <= store.getters.master
		},
		userNivel: state => {
			return state.user.nivel
		},
		manterDocumentos: state => {
			return state.user !== null && (state.user.nivel <= store.getters.administracao || state.user.manterDocumentos)
		},
		manterUsuarios: state => {
			return state.user !== null && (state.user.nivel <= store.getters.administracao || state.user.manterUsuarios)
		},
		manterDownloads: state => {
			return state.user !== null && (state.user.nivel <= store.getters.administracao || state.user.manterDownloads)
		}
	},
	mutations: {
		setUser(state, user) {
			state.user = user
		},
		setManterDocumentos(state, manterDocumentos) {
			state.user.manterDocumentos = manterDocumentos
		},
		setManterDownloads(state, manterDownloads) {
			state.user.manterDownloads = manterDownloads

		},
		setManterUsuarios(state, manterUsuarios) {
			state.user.manterUsuarios = manterUsuarios
		},
		setNivel(state, nivel) {
			state.user.nivel = nivel
		}
	},
	actions: {
		login({ commit, state }, user) {
			commit('setUser', user)
			localStorage.setItem(state.userKey, JSON.stringify(user))
			Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
		},
		logout({ commit, state }) {
			commit('setUser', null)
			localStorage.removeItem(state.userKey)
		},
		atualizarPermissoes({ commit }, usuario) {
			commit('setManterDocumentos', usuario.manterDocumentos)
			commit('setManterDownloads', usuario.manterDownloads)
			commit('setManterUsuarios', usuario.manterUsuarios)
			commit('setNivel', usuario.nivel)

		},
	}
}
export default moduleUsuario
