<template>
	<v-dialog v-model="hierarquiaDialog" max-width="450">
		<DeletarDialog
			:abrirDialog="excluir"
			@dialogClose="dialogCloseDeletar"
			@deletar="deletar"
			mensagem="Você realmente deseja excluir esse hierarquia?"
		/>
		<v-card>
			<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			<v-card-title>
				<v-icon left>mdi-account-group</v-icon>Cadastro de Grupo de Usuários
				<v-spacer />
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>
						<v-text-field
							label="Nome"
							v-model="hierarquia.nome"
							:readonly="!userMaster"
							autocomplete="off"
                            hide-details
						></v-text-field>
					</v-col>
				</v-row>
				<v-row v-if="userMaster" class="mb-1">
					<v-spacer />
					<v-btn class="mx-1" color="success" @click="salvar()">
						<v-icon left>mdi-content-save</v-icon>
						Salvar
					</v-btn>
					<v-btn v-if="id" class="mx-1" color="error" @click="abrirDialogDeletar">
						<v-icon left>mdi-delete</v-icon>
						deletar
					</v-btn>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import DeletarDialog from '../shared/DeletarDialog.vue'

export default {
	props: {
		id: String,
		dialog: Boolean,
	},
	components: { DeletarDialog },
	data() {
		return {
			loading: false,
			hierarquia: {},
			excluir: false,
		}
	},
	computed: {
		...mapGetters(['userMaster']),
		hierarquiaDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		async salvar() {
			if (this.id) {
				this.editar()
			} else {
				this.criar()
			}
		},
		async criar() {
			try {
				this.loading = true
				const response = await this.axios.post('/hierarquias', {
					nome: this.hierarquia.nome,
				})

				if (response.status == 201) {
					this.$store.dispatch('showSuccess', 'Hierarquia cadastrada com sucesso!')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async editar() {
			try {
				this.loading = true
				const response = await this.axios.put(`/hierarquias/${this.id}`, {
					nome: this.hierarquia.nome,
				})

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Hierarquia alterada com sucesso!')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		dialogClose() {
			this.hierarquia = {}
			this.$emit('dialogClose')
		},
		dialogCloseDeletar() {
			this.excluir = false
		},
		abrirDialogDeletar() {
			this.excluir = true
		},
		async loadHierarquia() {
			if (this.id) {
				try {
					this.loading = true
					const response = await this.axios.get(`/hierarquias/${this.id}`)
					this.hierarquia = response.data.hierarquia
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loading = false
				}
			}
		},
		async deletar() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/hierarquias/${this.id}`)
				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Hierarquia foi excluído com sucesso!')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		dialog() {
			if (this.dialog) this.loadHierarquia()
		},
	},
}
</script>

<style>
</style>