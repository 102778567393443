const moduleHierarquias = {
	state: {
		hierarquias: [],
	},
	getters: {
		hierarquias: state => { return state.hierarquias },
		administracao : state => { 
			const administracao = state.hierarquias.find((element) => element.nome == 'Administração');
			return administracao.nivel
		},
		master : state => { 
			const master = state.hierarquias.find((element) => element.nome == 'Master');
			return master.nivel
		}
	},
	mutations: {
		['ADD_HIERARQUIAS'](state, hierarquias) { state.hierarquias = hierarquias },
	},
	actions: {
		addHierarquias({ commit }, hierarquias) {
			commit('ADD_HIERARQUIAS', hierarquias)
		}
	},
}
export default moduleHierarquias
