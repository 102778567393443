<template>
	<div>
		<DeletarDialog
			:abrirDialog="excluir"
			@dialogClose="dialogCloseDeletar"
			@deletar="deletar"
			mensagem="Você realmente deseja excluir esse documento?"
		/>
		<v-dialog v-model="documentoDialog" max-width="1024" persistent>
			<v-card max-height="920" class="pb-5">
				<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
				<div v-if="editor">
					<v-card-title class="pb-0" v-if="!edicao">
						<v-spacer />
						<v-icon @click="dialogClose()"> mdi-close </v-icon>
					</v-card-title>
					<v-card class="mb-1 pa-2" v-if="edicao">
						<v-card-title>
							<v-icon left color="primary">mdi-book-open-blank-variant</v-icon>
							Editor de Documentos
							<v-spacer></v-spacer>
							<v-icon @click="dialogClose()"> mdi-close </v-icon>
						</v-card-title>
						<v-row justify="center" class="ma-0">
							<v-col cols="2">
								<v-container fluid>
									<v-combobox
										v-model="selectedProjeto"
										:items="projetos"
										item-value="id"
										item-text="nome"
										label="Projeto"
										dense
										hide-details
									></v-combobox>
								</v-container>
							</v-col>
							<v-col cols="2">
								<v-container fluid>
									<v-combobox
										v-model="selectedTopico"
										:items="topicos"
										item-value="id"
										item-text="nome"
										label="Topico"
										dense
										hide-details
									></v-combobox>
								</v-container>
							</v-col>
							<v-col cols="3">
								<v-text-field
									class="ma-0"
									placeholder="Título"
									label="Título"
									hide-details
									v-model="titulo"
								></v-text-field>
							</v-col>
							<v-col cols="3">
								<v-text-field
									class="ma-0"
									placeholder="Tags"
									label="Tags"
									hide-details
									v-model="tags"
								></v-text-field>
							</v-col>
							<v-col cols="2">
								<v-select
									label="Grupo"
									v-model="hierarquiaId"
									:readonly="!manterDocumentos"
									:items="hierarquias"
									item-text="nome"
									item-value="id"
									:disabled="loading"
								></v-select>
							</v-col>
						</v-row>

						<MenuEditor :editor="editor" class="mt-3 mb-3" />
					</v-card>
					<v-card class="scroll" max-height="500" elevation="0">
						<editor-content :editor="editor" class="ma-5" editable: false/>
					</v-card>
					<v-row justify="end" class="mr-2" v-if="manterDocumentos && !loading">
						<v-col cols="2" v-if="!edicao">
							<v-btn @click="editar()" width="100%" color="primary">Editar</v-btn>
						</v-col>
						<v-col cols="2" v-if="edicao && id">
							<v-btn @click="abrirDialogDeletar()" width="100%" color="error"> Excluir </v-btn>
						</v-col>
						<v-col cols="2" v-if="edicao">
							<v-btn @click="salvar()" width="100%" color="success"> Salvar </v-btn>
						</v-col>
					</v-row>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import DeletarDialog from '../shared/DeletarDialog.vue'
import { mapGetters, mapState } from 'vuex'
import Document from '@tiptap/extension-document'
import TextAlign from '@tiptap/extension-text-align'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Placeholder from '@tiptap/extension-placeholder'
import Image from '@tiptap/extension-image'
import MenuEditor from '../documentacao/MenuEditor.vue'

const CustomDocument = Document.extend({
	content: 'heading block*',
})

export default {
	props: {
		id: String,
		topicoId: String,
		projetoId: String,
		dialog: Boolean,
	},
	data: function () {
		return {
			excluir: false,
			loading: false,
			texto: '',
			editor: null,
			selectedProjeto: [],
			projetos: [],
			selectedTopico: [],
			topicos: [],
			tags: '',
			titulo: '',
			idProjeto: '',
			edicao: false,
			hierarquiaId: '',
			hierarquias: [],
		}
	},
	components: {
		DeletarDialog,
		EditorContent,
		MenuEditor,
	},
	computed: {
		...mapGetters(['manterDocumentos', 'userMaster', 'userNivel']),
		...mapState(['user']),
		documentoDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.selectedProjeto = []
			this.projetos = []
			this.selectedTopico = []
			this.topicos = []
			this.tags = ''
			this.titulo = ''
			this.idProjeto = ''
			this.edicao = false
			this.editor.setOptions({ editable: false })
			this.editor.commands.clearContent()

			this.$emit('dialogClose')
		},
		dialogCloseDeletar() {
			this.excluir = false
		},
		async loadDocumento() {
			this.loading = true
			if (this.id) {
				try {
					const documento = await this.axios.get(`/documentos/${this.id}`)

					this.editor.commands.insertContent(documento.data.documento.texto)

					if (this.manterDocumentos) {
						const projetos = await this.axios.get('/projetos/todos')
						const topicos = await this.axios.get(
							`/projetos/${documento.data.documento.topico.projeto.id}/topicos`
						)
						this.projetos = projetos.data.projetos
						this.selectedProjeto = documento.data.documento.topico.projeto

						this.idProjeto = documento.data.documento.topico.projeto.id

						this.topicos = topicos.data.topicos
						this.selectedTopico = documento.data.documento.topico
					}

					this.titulo = documento.data.documento.titulo
					this.hierarquiaId = documento.data.documento.hierarquiaId

					this.tags = documento.data.documento.tags
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loading = false
				}
			} else {
				const topico = await this.axios.get(`/topicos/${this.topicoId}`)

				const projetoId = topico.data.topico.projetoId

				const projeto = await this.axios.get(`/projetos/${projetoId}`)

				const projetos = await this.axios.get('/projetos')
				this.projetos = projetos.data.projetos

				const topicos = await this.axios.get(`/projetos/${projetoId}/topicos`)
				this.topicos = topicos.data.topicos

				this.idProjeto = projeto.data.projeto.id
				this.selectedProjeto = projeto.data.projeto

				this.selectedTopico = topico.data.topico

				this.editar()
				this.loading = false
			}
		},
		async salvar() {
			this.loading = true
			if (!this.id) {
				try {
					const response = await this.axios.post(`/documentos`, {
						titulo: this.titulo,
						texto: this.editor.getHTML(),
						topicoId: this.selectedTopico.id,
						tags: this.tags,
						hierarquiaId: this.hierarquiaId,
					})

					if (response.status == 201) {
						this.dialogClose()
						this.$store.dispatch('showSuccess', response.data.msg)
					} else if (response.status == 202) {
						this.$store.dispatch('showError', response.data.msg)
					}
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loading = false
				}
			} else {
				try {
					this.loading = true

					const response = await this.axios.put(`/documentos/${this.id}`, {
						titulo: this.titulo,
						texto: this.editor.getHTML(),
						tags: this.tags,
						topicoId: this.selectedTopico.id,
						hierarquiaId: this.hierarquiaId,
					})

					if (response.status == 204) {
						this.$store.dispatch('showSuccess', 'O cadastro foi alterado com sucesso!')
						this.dialogClose()
					}
				} catch (error) {
					this.$store.dispatch('showError', error)
				} finally {
					this.loading = false
				}
			}
		},
		abrirDialogDeletar() {
			this.excluir = true
		},
		async deletar() {
			try {
				this.loading = true
				const response = await this.axios.delete(`/documentos/${this.id}`)
				this.documento = response.data.documento
				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Download Excluído com sucesso!')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		addImage() {
			const url = window.prompt('URL')

			if (url) {
				this.editor.chain().focus().setImage({ src: url }).run()
			}
		},
		async get() {
			const response = await this.axios.get(`/documentos/17`)

			this.editor.commands.insertContent(response.data.documento.texto)
		},

		loadTexto() {
			if (this.texto) this.editor.commands.insertContent(this.texto)
		},
		editar() {
			this.edicao = true
			this.editor.setOptions({ editable: true })
		},
	},
	mounted() {
		this.editor = new Editor({
			editable: this.edicao ? true : false,
			extensions: [
				Image,
				TextAlign.configure({
					types: ['heading', 'paragraph'],
				}),
				CustomDocument,
				StarterKit.configure({
					document: false,
				}),
				Placeholder.configure({
					placeholder: ({ node }) => {
						if (node.type.name === 'heading') {
							return 'Título'
						}

						return 'Digite aqui seu texto'
					},
				}),
				Link.configure({
					openOnClick: false,
				}),
			],
		})

		this.editor.commands.setTextAlign('justify')
		this.loadTexto()
	},

	beforeUnmount() {
		this.editor.destroy()
	},
	watch: {
		async dialog() {
			if (this.dialog) {
				this.hierarquias = await this.retornarHierarquia(this.axios, this.userNivel)
				this.loadDocumento()
			}
		},
		async selectedProjeto() {
			if (this.selectedProjeto.id && this.selectedProjeto.id != this.idProjeto) {
				this.idProjeto = this.selectedProjeto.id
				const topicos = await this.axios.get(`/projetos/${this.selectedProjeto.id}/topicos`)
				this.topicos = topicos.data.topicos
				this.selectedTopico = ''
			}
		},
	},
}
</script>


<style lang="scss">
/* Basic editor styles */
.ProseMirror {
	> * + * {
		margin-top: 0.75em;
	}

	ul,
	ol {
		padding: 0 1rem;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
	}
}

.ProseMirror:focus {
	outline: none;
}

.ProseMirror pre {
	background: #0d0d0d;
	color: #fff;
	font-family: JetBrainsMono, monospace;
	padding: 0.75rem 1rem;
	border-radius: 0.5rem;
}

.is-active {
	background-color: pink;
}

.ProseMirror .is-empty::before {
	content: attr(data-placeholder);
	float: left;
	color: #ced4da;
	pointer-events: none;
	height: 0;
}
.scroll {
	overflow-y: auto;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #1976d2;
}
</style>