<template>
	<div>
		<LinkDialog :linkAntigo="link" @salvarlink="salvarlink" :dialog="linkDialog" @dialogClose="dialogClose" />
		<v-col>
			<v-row justify="start">
				<template v-for="(item, index) in itens" class="mb-3">
					<MenuItem :icon="item.icon" :key="index" :value="item.isActive()" @funcao="item.action" />
				</template>
			</v-row>
		</v-col>
	</div>
</template>

<script>
import MenuItem from './MenuItem.vue'
import LinkDialog from './LinkDialog.vue'

export default {
	components: {
		MenuItem,
		LinkDialog,
	},
	props: {
		editor: {
			type: Object,
			require: true,
		},
	},
	methods: {
		salvarlink(link) {
			if (link == '') this.editor.commands.unsetLink()
			else {
				this.editor.commands.unsetLink()
				this.editor.commands.toggleLink({ href: link })
			}
		},
		dialogClose() {
			this.linkDialog = false
			this.link = ''
		},
	},
	data() {
		return {
			linkDialog: false,
			link: '',
			itens: [
				{
					icon: 'mdi-format-bold',
					action: () => this.editor.chain().focus().toggleBold().run(),
					isActive: () => this.editor.isActive('bold'),
				},
				{
					icon: 'mdi-format-italic',
					action: () => this.editor.chain().focus().toggleItalic().run(),
					isActive: () => this.editor.isActive('italic'),
				},
				{
					icon: 'mdi-format-strikethrough-variant',
					action: () => this.editor.chain().focus().toggleStrike().run(),
					isActive: () => this.editor.isActive('strike'),
				},
				{
					icon: 'mdi-xml',
					action: () => this.editor.chain().focus().toggleCode().run(),
					isActive: () => this.editor.isActive('code'),
				},
				{
					icon: 'mdi-alphabetical-variant-off',
					action: () => this.editor.chain().focus().unsetAllMarks().run(),
					isActive: () => false,
				},
				{
					icon: 'mdi-format-pilcrow',
					action: () => this.editor.chain().focus().setParagraph().run(),
					isActive: () => this.editor.isActive('paragraph'),
				},
				{
					icon: 'mdi-format-header-1',
					action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
					isActive: () => this.editor.isActive('heading', { level: 1 }),
				},
				{
					icon: 'mdi-format-header-2',
					action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
					isActive: () => this.editor.isActive('heading', { level: 2 }),
				},
				{
					icon: 'mdi-format-header-3',
					action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
					isActive: () => this.editor.isActive('heading', { level: 3 }),
				},
				{
					icon: 'mdi-format-list-bulleted',
					action: () => this.editor.chain().focus().toggleBulletList().run(),
					isActive: () => this.editor.isActive('bulletList'),
				},
				{
					icon: 'mdi-format-list-numbered',
					action: () => this.editor.chain().focus().toggleOrderedList().run(),
					isActive: () => this.editor.isActive('orderedList'),
				},
				{
					icon: 'mdi-format-align-left',
					action: () => this.editor.chain().focus().setTextAlign('left').run(),
					isActive: () => this.editor.isActive({ textAlign: 'left' }),
				},
				{
					icon: 'mdi-format-align-center',
					action: () => this.editor.chain().focus().setTextAlign('center').run(),
					isActive: () => this.editor.isActive({ textAlign: 'center' }),
				},
				{
					icon: 'mdi-format-align-right',
					action: () => this.editor.chain().focus().setTextAlign('right').run(),
					isActive: () => this.editor.isActive({ textAlign: 'right' }),
				},
				{
					icon: 'mdi-format-align-justify',
					action: () => this.editor.chain().focus().setTextAlign('justify').run(),
					isActive: () => this.editor.isActive({ textAlign: 'justify' }),
				},
				{
					icon: 'mdi-arrow-u-left-top',
					action: () => this.editor.chain().focus().undo().run(),
					isActive: () => false,
				},
				{
					icon: 'mdi-arrow-u-right-top',
					action: () => this.editor.chain().focus().redo().run(),
					isActive: () => false,
				},
				{
					icon: 'mdi-image',
					action: () => {
						const url = window.prompt('URL')

						if (url) {
							this.editor.chain().focus().setImage({ src: url }).run()
						}
					},
					isActive: () => false,
				},
				{
					icon: 'mdi-link',
					action: () => {
						const previousUrl = this.editor.getAttributes('link').href

						this.link = previousUrl

						this.linkDialog = true

						/*if (link === null) return
						else if (link == '') this.editor.commands.unsetLink()
						else this.editor.commands.toggleLink({ href: link })*/

						/*if(this.editor.isActive('link')){
							this.editor.commands.unsetLink()

						}else
						{const link = window.prompt('link')
						if (link) {
							this.editor.commands.toggleLink({ href: link })
							//this.editor.chain().focus().extendMarkRange('link').setLink({ href: link }).run()
						}}*/
					},
					isActive: () => this.editor.isActive('link'),
				},
			],
		}
	},
}
</script>
